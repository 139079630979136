import router from '@/router'

export default {
  state: { 
    carousel_list: [], 
    carousel_items: [],
  },
  getters: {
    getCarouselList: s => s.carousel_list,
    getCarouselItems: s => s.carousel_items,
  },
  mutations: {
    updateCarouselList(s, carousel_list) {
      s.carousel_list = carousel_list;
    },
    setCarouselItems: (s, items) => s.carousel_items = items, 
  },
  actions: {
    fetchCarouselList(ctx) {
      return this.$axios({
          method: 'GET',
          url: '/api/carousel/carouselcountry/', 
      })
          .then((response) => {
              const result = response.data;
              ctx.commit('updateCarouselList', result);
          })
          .catch((error) => {
              console.log(error)
          });
    },
    fetchCarouselItems(ctx, { slug, filter = {} }) {
      let queryObj = ctx.getters.getFilter;
      queryObj.country = router.currentRoute.params.country;
      let obj = {};
      for ( let key in queryObj ) {
        if ( queryObj[key] ) {
          obj[key] = queryObj[key]
        }
      }
      const queryStr = new URLSearchParams(obj).toString();

      return this.$axios({
        method: 'GET',
        url: `/api/carousel/carouselitem/?carousel_slug=${slug}&${queryStr}`, 
        }).then(response => {
            const result = response.data.results;
            ctx.commit('setCarouselItems', result);
          })
          .catch((error) => {
              console.log(error)
          });
    },
    addProductToCarousel(ctx, payload) {
      const fd = new FormData();
      for ( let key in payload ) {
        if ( payload[key] ) {
          fd.append(key, payload[key])
        }
      };

      return this.$axios({
        method: 'POST',
        url: `/api/carousel/carouselitem/`,
        data: fd 
      }).then(async response => {
          const res = response.data;
          await ctx.dispatch('fetchCarouselItems', {
            slug: router.currentRoute.params.slug,
          })
        })
        .catch((error) => {
            console.log(error)
        });
    },
    deleteProductFromCarousel(ctx, ids) {
      const fd = new FormData();
      fd.append('ids', ids)

      return this.$axios({
        method: 'POST',
        url: `/api/carousel/carouselitem/to_delete/`,
        data: fd 
      }).then(async response => {
          const res = response.data;
          await ctx.dispatch('fetchCarouselItems', {
            slug: router.currentRoute.params.slug,
          })
        })
        .catch((error) => {
            console.log(error)
        });
    },
    changeOrderProductFromCarousel(ctx, { id, order }) {
      const fd = new FormData();
      fd.append('order', order);
      
      return this.$axios({
        method: 'POST',
        url: `/api/carousel/carouselitem/${id}/position/`,
        data: fd
      }).then(async response => {
        const result = response.data;
        await ctx.dispatch('fetchCarouselItems', {
          slug: router.currentRoute.params.slug,
        })
      })
    },
    changeDateProductsFromCarousel(ctx, payload) {
      const fd = new FormData();
      for ( let key in payload ) {
        if ( payload[key] ) {
          fd.append(key, payload[key])
        }
      };

      return this.$axios({
        method: 'POST',
        url: '/api/carousel/carouselitem/change_date/',
        data: fd
      }).then(async response => {
        const result = response.data;
        await ctx.dispatch('fetchCarouselItems', {
          slug: router.currentRoute.params.slug,
        })
      })
    },
    changeStatusProductsFromCarousel(ctx, payload) {
      const fd = new FormData();
      for ( let key in payload ) {
        fd.append(key, payload[key])
      };

      return this.$axios({
        method: 'POST',
        url: '/api/carousel/carouselitem/status/',
        data: fd
      }).then(async response => {
        const result = response.data;
        await ctx.dispatch('fetchCarouselItems', {
          slug: router.currentRoute.params.slug,
        })
      })
    }
  }
}

export default ({
  state: {
    products: {},
    products_for_carousel: [],
  },
  getters: {
    getProducts: s => s.products,
    getProductsForCarousel: s => s.products_for_carousel,
  },
  mutations: {
    updateProducts(s, products) {
      s.products = products
    },
    clearProducts(s) {
      s.products = {}
    },
    setProductsForCarousel(s, list) {
      s.products_for_carousel = list
    }
  },
  actions: {
    async searchProductsObj(ctx, data) {
      let products = {};
      for (let lang of data.langs) {
        let resp = await ctx.dispatch('searchProducts', {
          country: data.country, 
          queryString: data.queryString, 
          lang: lang
        })
        products[lang] = resp.length ? resp : 'empty'
      }
      ctx.commit('updateProducts', products);
    },
    searchProducts(ctx, data) {
      return this.$axios({
          method: 'GET',
          url: `api/promo/proxy-request/${data.country}/?q=${data.queryString}&lang=${data.lang}&size=100`,
      })
          .then((response) => {
              const products = response.data;
              return products.results
          })
          .catch((error) => {
              console.log(error)
          });
    },
    async clearSearch(ctx) {
      ctx.commit('clearProducts');
    },

    async searchProductsCarousel(ctx, data) {
      return this.$axios({
        method: 'GET',
        url: `api/promo/proxy-request/${data.country}/?q=${data.queryString}&lang=${data.lang}&size=100`,
      }).then((response) => {
            const products = response.data.results;
            ctx.commit('setProductsForCarousel', products)
        })
        .catch((error) => {
            console.log(error)
        });
    }
  },
})
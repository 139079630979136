// import 'vuetify/dist/vuetify.min.css'

import Vue from 'vue'
import Vuetify from 'vuetify'
import ru from 'vuetify/lib/locale/ru'

Vue.use(Vuetify);

const opts = {
    lang: {
    locales: { ru },
    current: 'ru'
    }
}

export default new Vuetify(opts)

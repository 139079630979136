import Vue from 'vue'
import VueRouter from 'vue-router'
import { Trans } from "@/plugins/Translation"

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

Vue.use(VueRouter);

const routes = [
    {
      path: '',
      name: 'Auth',
      component: load("Auth"),
      meta: {
        layout: 'auth',
      },
    },
    {
      path: '/help',
      name: 'Help',
      component: load("help/Help"),
      meta: {
        layout: 'help',
        auth: true
      }
    },
    {
      path: '/help/document/:id',
      name: 'HelpDocument',
      component: load("help/Help"),
      meta: {
        layout: 'help',
        auth: true
      }
    },
    {
      path: '/:country/promo/',
      name: 'PromoList',
      component: load("promo/PromoList"),
      meta: {
        layout: 'promo',
        auth: true
      }
    },
    {
      path: '/:country/promo/detail-promo/:id/',
      name: 'PromoDetailPromo',
      component: load("promo/PromoDetailPromo"),
      meta: {
        layout: 'promoDetail',
        auth: true
      }
    },
    {
      path: '/:country/promo/detail-product/:id/',
      name: 'PromoDetailProduct',
      component: load("promo/PromoDetailProduct"),
      meta: {
        layout: 'promoDetail',
        auth: true
      }
    },
    {
      path: '/:country/promo/carousel/:slug',
      name: 'PromoCarousel',
      component: load("promo/PromoCarousel"),
      meta: {
        layout: 'promo',
        auth: true
      }
    },
    {
      path: "*",
      name: "Error",
      component: load("Error"),
      meta: {
        layout: 'auth'
      }
    },
];
let router; 
  router = new VueRouter({
    mode: 'history',
    base: "/",
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        if (to.hash) {
          return {
            selector: to.hash
            // , offset: { x: 0, y: 10 }
          }
        } else {
          return { x: 0, y: 0 }
        }
       
      }
    },
    routes,
  });

router.beforeEach((to, from, next) => {
  Trans.routerMiddleware(to, from, next)
});

export default router

export default ({
    state: {
       errors: []
    },
    getters: {
        getAuthErrors: s => s.errors,
    },
    mutations: {
        updateAuthErrors(state, error) {
            state.errors = error
        } 
    },
    actions: {
        autorization(ctx, userData) {
            const fd = new FormData();
            fd.append('username', userData.login);
            fd.append('password', userData.pass);

            return this.$axios({
                method: 'POST',
                url: 'api/auth/login',
                data: fd,
                headers: { Authorization: '' }
            })
                .then((response) => {
                    const result = response.data;
                    localStorage.setItem('amw_cms_token', result.token);
                    localStorage.setItem('amw_cms_refresh', result.refresh);
                    this.$axios.defaults.headers.common.Authorization = `Bearer ${result.token}`;
                    ctx.commit('updateUser', result.user);
                })
                .catch((error) => {
                    localStorage.removeItem('amw_cms_token');
                    // delete axios.defaults.headers.common.Authorization; // Что происходит в этой строке? Думаю это надо будет добавить в logout
                    ctx.commit('updateAuthErrors', error.response.data.non_field_errors);
                    ctx.dispatch('fetchAlerts', {
                        type: 'error',
                        alerts: error.response.data.non_field_errors
                    })
                });
        },
        logout(ctx) {
            delete this.$axios.defaults.headers.common.Authorization;
            localStorage.removeItem("amw_cms_token")
            localStorage.removeItem("amw_cms_refresh")
            ctx.dispatch('clearUser')
        }

    },
})
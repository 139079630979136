export default ({
    state: {
        user: {}
    },
    getters: {
        User(state) {
            return state.user;
        }
    },
    mutations: {
        updateUser(state, user) {
            state.user = user;
        },
    },
    actions: {
        fetchUser(ctx) {
            return this.$axios({
                method: 'GET',
                url: 'api/auth/session',
            })
                .then((response) => {
                    const result = response.data;
                    ctx.commit('updateUser', result.user);
                })
                .catch((error) => {
                    
                });
        },
        clearUser(ctx) {
            ctx.commit('updateUser', {});
        }
    },
})
import auth from './modules/auth'
import user from './modules/user'
import page from './modules/page'
import promo from './modules/promo'
import rules from './modules/rules'
import search from './modules/search'
import alerts from './modules/alerts'
import filter from './modules/filter'
import carousel from './modules/carousel'

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    auth,
    page,
    promo,
    rules,
    search,
    alerts,
    filter,
    carousel,
  }
})

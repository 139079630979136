import router from '@/router'

export default ({
  state: {
    promo_list: [],
    promo: {},
    user_types: [],
    categories: [],
    countries: [],
    badges: [],
  },
  getters: {
    getPromo: s => s.promo,
    getPromoList: s => s.promo_list,
    userTypes: s => s.user_types,
    Categories: s => s.categories,
    Countries: s => s.countries,
    Badges: s => s.badges,
  },
  mutations: {
    updatePromo(s, promo) {
      s.promo = promo;
    },
    updatePromoList(s, promo_list) {
      s.promo_list = promo_list;
    },
    updateUserTypes(state, user_types) {
      state.user_types = user_types;
    },
    updateCategories(state, categories) {
        state.categories = categories;
    },
    updateCountries(state, countries) {
      state.countries = countries;
    },
    updateBadges(state, badges) {
      state.badges = badges;
    },
    updatePromoImage(state, image) {
      state.promo.image = image;
    },
    updatePromoFile(state, data) {
      let translate = state.promo.translates.find((el) => el.language.id = data.lang)
      translate.promo_condition_file = data.file;
    },
  },
  actions: {
    fetchPromo(ctx, data) {
      return this.$axios({
        method: 'GET',
        url: `api/promo/promotion/${data.id}/?content_type=${data.type}`,
      })
        .then((response) => {
          const res = response.data;
          ctx.commit('updatePromo', res);
        })
        .catch((error) => {

        });
    },
    fetchUpdatePromo(ctx, data) {
      return this.$axios({
        method: 'PATCH',
        url: `api/promo/promotion/${data.id}/`,
        data: data.body
      })
        .then((response) => {
          const res = response.data;
          ctx.dispatch('fetchPromo', {id: data.id, type: data.type})
          ctx.dispatch('changeStatus', {ids: JSON.stringify([data.id]), is_test: false, is_public: false})
          if(data.save) {
            ctx.dispatch('fetchAlerts', {
              type: 'notify',
              alerts: ["Сохранено"]
            })
          }
        })
        .catch((error) => {

        });
    },
    fetchDeletePromo(ctx, id) {
      return this.$axios({
        method: 'DELETE',
        url: `api/promo/promotion/${id}/`,
      })
        .then((response) => {
        })
        .catch((error) => {

        });
    },
    fetchCreatePromo(ctx, data) {
      return this.$axios({
        method: 'POST',
        url: `api/promo/promotion/`,
        data: {
          content_type: data.type,
          country: data.country,
          available_for: data.available_for
        }
      })
        .then(async (response) => {
          const res = response.data;
          await ctx.dispatch('fetchPromo', {id: res.id, type: res.content_type})
        })
        .catch((error) => {

        });
    },
    fetchPromoList(ctx, filter = {}) {
      let queryObj = ctx.getters.getFilter;
      queryObj.country = router.currentRoute.params.country;
      let obj = {};
      for ( let key in queryObj ) {
        if ( queryObj[key] ) {
          obj[key] = queryObj[key]
        }
      }
      const queryStr = new URLSearchParams(obj).toString();

      return this.$axios({
        method: 'GET',
        url: `api/promo/promotion/${ queryStr ? '?' + queryStr : '' }`,
      })
        .then((response) => {
          const res = response.data;
          ctx.commit('updatePromoList', res.results);
        })
        .catch((error) => {

        });
    },
    fetchUserTypes(ctx) {
      return this.$axios({
          method: 'GET',
          url: 'api/promo/usertype/',
      })
          .then((response) => {
              const result = response.data;
              ctx.commit('updateUserTypes', result);
          })
          .catch((error) => {
              console.log(error)
          });
    },
    fetchCategories(ctx) {
      return this.$axios({
          method: 'GET',
          url: 'api/promo/category/',
      })
          .then((response) => {
              const result = response.data;
              ctx.commit('updateCategories', result);
          })
          .catch((error) => {
              console.log(error)
          });
    },
    fetchCountries(ctx) {
      return this.$axios({
          method: 'GET',
          url: 'api/promo/country/',
      })
          .then((response) => {
              const result = response.data;
              ctx.commit('updateCountries', result);
          })
          .catch((error) => {
              console.log(error)
          });
    },
    fetchBadges(ctx) {
      return this.$axios({
          method: 'GET',
          url: 'api/promo/badge/',
      })
          .then((response) => {
              const result = response.data;
              ctx.commit('updateBadges', result);
          })
          .catch((error) => {
              console.log(error)
          });
    },
    createBadges(ctx, data) {
      return this.$axios({
          method: 'POST',
          url: 'api/promo/badge/',
          data: data
      })
          .then((response) => {
              ctx.dispatch('fetchBadges');
          })
          .catch((error) => {
              console.log(error)
          });
    },
    editBadges(ctx, data) {
      return this.$axios({
          method: 'PATCH',
          url: `api/promo/badge/${data.id}/`,
          data: data.body
      })
          .then((response) => {
              ctx.dispatch('fetchBadges');
          })
          .catch((error) => {
              console.log(error)
          });
    },
    deleteBadges(ctx, id) {
      return this.$axios({
          method: 'DELETE',
          url: `api/promo/badge/${id}/`,
      })
          .then((response) => {
              ctx.dispatch('fetchBadges');
          })
          .catch((error) => {
              console.log(error)
          });
    },
    async clearPromo(ctx) {
      ctx.commit('updatePromo', {});
    },
    addPromoImage(ctx, data) {
      return this.$axios({
          method: 'POST',
          url: 'api/promo/imagestorage/',
          data: data
      })
          .then((response) => {
              const result = response.data;
              ctx.commit('updatePromoImage', result.file);
          })
          .catch((error) => {
              console.log(error)
          });
    },
    addPromoFile(ctx, data) {
      return this.$axios({
          method: 'POST',
          url: 'api/promo/filestorage/',
          data: data.body
      })
          .then((response) => {
              const result = response.data;
              ctx.commit('updatePromoFile', {file: result.file, lang: data.lang});
          })
          .catch((error) => {
              console.log(error)
          });
    },
    deletePromoFile(ctx, id) {
      return this.$axios({
          method: 'DELETE',
          url: `api/promo/filestorage/${id}`,
      })
          .then((response) => {
             
          })
          .catch((error) => {
              console.log(error)
          });
    },
    changePromoOrder(ctx, { id, order }) {
      const fd = new FormData();
      fd.append('order', order);
      
      return this.$axios({
        method: 'POST',
        url: `/api/promo/promotion/${id}/position/`,
        data: fd
      }).then((response) => {
        const result = response.data;
        ctx.dispatch('fetchPromoList');
      })
    },
    changeDates(ctx, data) {
      const fd = new FormData();
      for ( let key in data ) {
        if ( data[key] ) {
          fd.append(key, data[key])
        }
      };

      return this.$axios({
        method: 'POST',
        url: '/api/promo/promotion/change_date/',
        data: fd
      }).then((response) => {
        const result = response.data;
        ctx.dispatch('fetchPromoList');
      })
    },
    changeStatus(ctx, data) {
      const fd = new FormData();
      for ( let key in data ) {
        fd.append(key, data[key])
      };

      return this.$axios({
        method: 'POST',
        url: '/api/promo/promotion/status/',
        data: fd
      }).then((response) => {
        const result = response.data;
        if(router.currentRoute.name === "PromoList") {
          ctx.dispatch('fetchPromoList');
        }
      })
    },
    deletePromo(ctx, data) {
      const fd = new FormData();
      fd.append('ids', data);

      return this.$axios({
        method: 'POST',
        url: '/api/promo/promotion/to_delete/',
        data: fd
      }).then((response) => {
        const result = response.data;
        ctx.dispatch('fetchPromoList');
      })
    },
  },
})

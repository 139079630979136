export default ({
    state: {
        document: {},
        menu: []
    },
    getters: {
        Document(state) {
            return state.document;
        },
        Menu(state) {
            return state.menu
        }
    },
    mutations: {
        updateDocument(state, document) {
            state.document = document;
        },
        updateMenu(state, menu) {
            state.menu = menu;
        },
    },
    actions: {
        fetchMenu(ctx) {
            return this.$axios({
                method: 'GET',
                url: 'api/handbook/page/tree/',
            })
                .then((response) => {
                    const result = response.data;
                    ctx.commit('updateMenu', result);
                })
                .catch((error) => {
                    
                });
        },
        addPage(ctx, data) {
            return this.$axios({
                method: 'POST',
                url: 'api/handbook/page/',
                data: data.body
            })
                .then(async (response) => {
                    ctx.dispatch('fetchMenu')
                })
                .catch((error) => {
                    
                });
        },
        changePage(ctx, data) {
            return this.$axios({
                method: 'PATCH',
                url: `api/handbook/page/${data.id}/`,
                data: data.body
            })
                .then(() => {
                    ctx.dispatch('fetchMenu')
                })
                .catch((error) => {
                    
                });
        },
        deletePage(ctx, id) {
            return this.$axios({
                method: 'DELETE',
                url: `api/handbook/page/${id}/`,
            })
                .then(() => {
                    ctx.dispatch('fetchMenu')
                })
                .catch((error) => {
                    
                });
        },
        createDocument(ctx, data) {
            return this.$axios({
                method: 'POST',
                url: `api/handbook/block/bulk_create/`,
                data
            })
                .then(() => {
                   
                })
                .catch((error) => {
                    
                });
        },
        getDetailDocument(ctx, id) {
            return this.$axios({
                method: 'GET',
                url: `api/handbook/page/${id}/`,
            })
                .then((response) => {
                    const result = response.data;
                    ctx.commit('updateDocument', result);
                })
                .catch((error) => {
                    
                });
        },
        // changeDetailDocument(ctx, data) {
        //     return this.$axios({
        //         method: 'PATCH',
        //         url: `api/handbook/block/bulk_update/`,
        //         data
        //     })
        //         .then((response) => {
        //             const result = response.data;
        //             ctx.commit('updateDocument', result);
        //         })
        //         .catch((error) => {
                    
        //         });
        // }
    },
})
import '@/assets/scss/styles.scss'

import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'

import router from './router'
import store from './store'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import axios from 'axios'
import vuetify from '@/plugins/vuetify'

import { i18n } from './i18n'
import { Trans } from './plugins/Translation'

import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/kk';
import 'moment/locale/uk';

axios.defaults.baseURL = `${process.env.VUE_APP_SOCIAL_HOST}/`;
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('amw_cms_token')}`;

axios.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        if(localStorage.getItem("amw_cms_refresh")) {
          originalRequest._retry = true;
          let fd = new FormData()
          fd.append('refresh', localStorage.getItem("amw_cms_refresh"))
          await fetch(`${process.env.VUE_APP_SOCIAL_HOST}api/auth/refresh`,
              {
                method: "POST",
                body: fd
              })
              .then(async function(res) {
                  if (res.status === 200) {
                      let result = await res.json()
                      // 1) put token to LocalStorage
                      localStorage.setItem("amw_cms_token", result.access);
  
                      // 2) Change Authorization header
                      axios.defaults.headers.common.Authorization = 'Bearer ' + result.access;
                      originalRequest.headers.Authorization = 'Bearer ' + result.access;
                      // 3) return originalRequest object with Axios.
                      return axios(originalRequest);
                  } else {
                    localStorage.removeItem('amw_cms_token');
                    localStorage.removeItem('amw_cms_refresh');
                    delete axios.defaults.headers.common.Authorization;
                    router.push({name: 'Auth'});
                  }
              })
        } else {
          localStorage.removeItem('amw_cms_token');
          delete axios.defaults.headers.common.Authorization;
          router.push({name: 'Auth'}); //, query:  { back_url: this.$route.path}
        }
        
    }
  return Promise.reject(error);
});

Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
moment.locale('ru')
Vue.prototype.$moment = moment;
Vue.config.productionTip = false

Vue.prototype.$axios = axios
Vuex.Store.prototype.$axios = axios


new Vue({
  router,
  store,
  i18n,
  vuetify,
  axios,
  render: h => h(App),
}).$mount('#app')

<template>
  <div>
    <transition name="scroll-y-fade">
      <v-alert v-if="getAlerts && Object.keys(getAlerts).length"
        class="alert"
        :class="this.getAlerts.type"
        dense
        dismissible
        icon="mdi-information"
        outlined
        :color="getAlertColor()"
        text
        @input="clearAlerts"
        close-icon="mdi-close"
        :value="!!(getAlerts && Object.keys(getAlerts).length)"
        transition="scroll-y-transition"
      >
        {{ getAlerts.alerts[0] }}
      </v-alert>
    </transition>

    <vue-extend-layouts/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueExtendLayouts from 'vue-extend-layout'

export default {
  name: 'App',
  components: { VueExtendLayouts },
  computed: {
    ...mapGetters(['getAlerts', 'User']),
  },
  methods: {
    ...mapActions(['clearAlerts', 'fetchUser']),
    getAlertColor() {
      switch (this.getAlerts.type) {
        case ('error'):
          return '#EB174B'
        case ('notify'):
          return '#008FC5'
        default:
          return '#000'
      }
    }
  },
  mounted() {
    if(!Object.keys(this.User).length && (localStorage.getItem("amw_cms_token") || localStorage.getItem("amw_cms_refresh"))) {
      this.fetchUser()
    }
  },
  watch: {
    getAlerts() {
      if (this.getAlerts && Object.keys(this.getAlerts).length) {
        setTimeout(() => {
          this.clearAlerts()
        }, 3000)
      }
    },
    User() {
      if(!Object.keys(this.User).length && (localStorage.getItem("amw_cms_token") || localStorage.getItem("amw_cms_refresh"))) {
       this.fetchUser()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.alert {
  position: fixed;
  top: 0;
  transform: translateY(100px);
  right: 30px;
  z-index: 9999999999;

  &.error {
    &::before {
      opacity: 1;
      background-color: #fde6eb;
      z-index: -1;
    }
  }

  @media screen and (max-width: 600px) {
    position: fixed;
    top: 59px;
    transform: translateX(50%);
    right: 50%;
    z-index: 100;
    width: 90%;
  }
}
</style>
export default ({
    state: {
        rules: {
            notEmpty: v => !!v || '',
            checkboxReq:  v => v.length > 0 || '',
            color: v => /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(v) || "Не является корректным кодом HEX",
            maxLength: function(length) {
               return  v => !v || v.length <= length 
            },
            Link: v => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(v) || !v
        }
    },
    getters: {
        Rules(state) {
            return state.rules;
        }
    },
})
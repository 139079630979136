import { i18n } from '../i18n'

const Trans = {
    get defaultLocale() {
        return  process.env.VUE_APP_I18N_LOCALE
    },
    get supportedLocales() {
        return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');
    },
    get currentLocale() {
        return i18n.locale
    },
    set currentLocale(locale) {
        i18n.locale = locale;
    },
    changeLocale(locale) {
        if (!Trans.isLocaleSupported(locale)) return Promise.resolve(Trans.defaultLocale);
        if (i18n.locale === locale) return Promise.resolve(locale);
       
        return Trans.loadLocaleFile(locale).then(msgs => {
            i18n.setLocaleMessage(locale, msgs.default || msgs)
            return Trans.setI18nLocaleInServices(locale);

        });
    },
    isLocaleSupported(locale) {
        return Trans.supportedLocales.includes(locale);
    },
    loadLocaleFile(locale) {
        return import(`@/locales/ru.json`);
    },
    setI18nLocaleInServices(locale) {
        Trans.currentLocale = locale;
        document.querySelector("html").setAttribute("lang", locale);
        return locale
    },
    async routerMiddleware(to, from, next) {
        const isAuth = localStorage.getItem('amw_cms_token');
        if(to.name === "Auth") {
            if ( isAuth ) {
                
                next({ 
                    name: "PromoList", 
                    params: { country: 'ru' }
                });
            } else { next() }
        }
        if (to.matched.some(record => record.meta.auth)) {
            if ( isAuth ) {
                next();
            } else { next({name: "Auth"}) }
          }  else {
            next();
          }
        const country = to.params.country;
        if(country && !Trans.isLocaleSupported(country)) return next({name: to.name, params: {country: 'ru'}}) 
        return Trans.changeLocale(country).then(() => next())

    },
    i18nRoute(to) {
        return {
            ...to,
            params: {
                ...to.params
            }
        }
    }

}

export { Trans }
